<template>
  <div>
    <div class="info">
      <p>我的关注</p>
    </div>
    <div class="choice">
      <!-- 消息提示有几个 -->
      <el-badge :value="a" class="item">
        <p class="active" ref="lie1" @click="lie(1)">关注我的人</p>
      </el-badge>
      <el-badge :value="b" class="item">
        <p ref="lie2" @click="lie(2)">我关注的人</p>
      </el-badge>
    </div>

    <!-- 访问框 -->
    <div class="follow">
      <!-- 关注我的 -->
      <div class="" v-show="xiao">
        <!-- 数据为空 -->
        <div class="kong" v-show="i">
          <img src="../../../../assets/images/message/guanzhu.png" alt="" />
          <p style="height: 40px">暂时还没有人关注你</p>
          <div class="btn-box" @click="chuji">提高我的展示机会</div>
        </div>

        <!-- 关注我的 -->
        <div v-if="isVipMember == 1">
          <ul class="messages-li" v-show="!i">
            <li v-for="(item, index) in Benoticed" :key="index">
              <!-- 头像 -->
              <div class="touxi" @click="ziliao(item.memberId)">
                <!-- <div class="xixi" > -->
                <img :src="item.Img" alt="" />
                <!-- </div> -->
              </div>
              <div class="mess-box">
                <div class="mess-name">
                  <!-- <p>{{ item.nickName }} <i>图标</i></p> -->
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      vertical-align: middle;
                    "
                  >
                    <p style="margin-right: 10px; font-weight: bold">
                      {{ item.nickName }}
                    </p>
                    <img
                      :src="item.isVipMember == 0 ? `` : `${MemberIcon}`"
                      alt=""
                      style="margin-right: 5px"
                    />
                    <img
                      :src="
                        item.realAuthStatus == 2 ? `${realAuthStatusIcon}` : ``
                      "
                      alt=""
                    />
                  </div>
                </div>
                <p class="qianmin" style="">
                  <span v-if="item.age">{{ item.age + "岁" }}</span>
                  <span v-if="item.workAddrCityName"
                    >&nbsp;|&nbsp;{{ item.workAddrCityName }}</span
                  >
                  <span v-if="item.occBigCategory">
                    &nbsp;|&nbsp; {{ item.occBigCategory }}
                  </span>
                  <span v-if="item.height"
                    >&nbsp;|&nbsp;{{ item.height + "cm" }}
                  </span>
                  <span v-if="item.education"
                    >&nbsp;|&nbsp;{{ item.education }}
                  </span>
                  <span v-if="item.buyCarStatus"
                    >&nbsp;|&nbsp;{{ item.buyCarStatus }}
                  </span>
                  <span v-if="item.houseStatus"
                    >&nbsp;|&nbsp;{{ item.houseStatus }}
                  </span>
                </p>
                <!-- 时间 -->
                <p
                  class="time"
                  v-if="
                    new Date(item.NewTime).getDate() == new Date().getDate() &&
                    new Date(item.NewTime).getMonth() ==
                      new Date().getMonth() &&
                    new Date(item.NewTime).getYear() == new Date().getYear()
                  "
                >
                  {{ $moment(item.NewTime).format("H:mm ") }}
                  <!-- {{ $moment(item.NewTime).calendar() }} -->
                </p>
                <p
                  class="time"
                  v-else-if="
                    new Date(item.NewTime).getDate() ==
                      new Date().getDate() - 1 &&
                    new Date(item.NewTime).getMonth() ==
                      new Date().getMonth() &&
                    new Date(item.NewTime).getYear() == new Date().getYear()
                  "
                >
                  <!-- {{ $moment(item.NewTime).subtract(1, "days").calendar() }} -->
                  {{ $moment(item.NewTime).calendar() }}
                </p>
                <p class="time" v-else>
                  {{ $moment(item.NewTime).format("MMM Do") }}
                </p>
              </div>
              <div class="Btn-box">
                <!-- 打招呼 -->
                <div>
                  <div class="Btn" v-if="item.show" @click="send(item)">
                    <img
                      class="z"
                      src="../../../../assets/images/info/mess-aL.png"
                      alt=""
                    />
                    <img
                      class="b"
                      src="../../../../assets/images/info/mess-aaLL.png"
                      alt=""
                    />
                    打招呼
                  </div>
                  <div class="Btn-z" v-if="!item.show">已打招呼</div>
                </div>

                <div class="Btn" @click="gz(item)">
                  <img
                    src="../../../../assets/images/message/yezai_aixin.png"
                    alt=""
                    class="z"
                  />
                  <img
                    v-if="item.browseFollowStatus == 1"
                    src="../../../../assets/images/info/mess-aixin.png"
                    alt=""
                    class="b"
                  />
                  <div class="z">
                    {{ item.browseFollowStatus == 1 ? "关注" : "已关注" }}
                  </div>
                  <div v-if="item.browseFollowStatus == 1" class="b">关注</div>
                  <div v-if="item.browseFollowStatus == 0" class="b">
                    取消关注
                  </div>
                </div>
                <!-- 发消息01 -->
                <div class="Btn" @click="chat(item.memberId)">
                  <img
                    class="z"
                    src="../../../../assets/images/message/yezai_message_mail.png"
                    alt=""
                  />
                  <img
                    class="b"
                    src="../../../../assets/images/info/mess-e.png"
                    alt=""
                  />
                  发消息
                </div>
              </div>
            </li>

            <!-- 分页处理 -->
            <div class="low">
              <el-pagination
                :current-page="currentPage4"
                :hide-on-single-page="value"
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="handleCurrentChangeL"
              >
              </el-pagination>
            </div>
          </ul>
        </div>
        <div v-else class="kong" v-show="!i">
          <img src="../../../../assets/images/message/guanzhu.png" alt="" />
          <p style="height: 40px">
            有
            <span style="color: #fd686e">{{ Benoticed.length }}</span>
            人关注你，想知道ta是谁吗
          </p>
          <p style="margin-top: 5px">开通也在特权立即查看</p>
          <div class="btn-gz">
            <div class="btn-gz-a" @click="member">立即购买</div>
            <div class="btn-gz-b" @click="member">了解特权</div>
          </div>
        </div>
      </div>

      <!-- (我关注的) -->
      <div class="" v-show="hong">
        <!-- 数据为空 -->
        <div class="kong" v-show="s">
          <img src="../../../../assets/images/message/guanzhu.png" alt="" />
          <p style="text-align: center; line-height: 20px">
            你还没有关注过任何人呢 <br />
            关注的越多，脱单的概率才越高
          </p>
          <div class="btn-box" @click="chuji">快去看看有没有你喜欢的人吧</div>
        </div>
        <!-- --------------- style="max-height: 1530px"-->
        <ul class="messages-li" v-show="!s">
          <li v-for="(item, index) in noticed" :key="index">
            <!-- 头像 -->
            <div class="touxi" @click="ziliao(item.objId)">
              <!-- <div class="xixi" > -->
              <img :src="item.Img" alt="" />
              <!-- </div> -->
            </div>
            <div class="mess-box">
              <div class="mess-name">
                <!-- <p>{{ item.browseNickName }} <i>图标</i></p> -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    vertical-align: middle;
                  "
                >
                  <p style="margin-right: 10px; font-weight: bold">
                    {{ item.browseNickName }}
                  </p>
                  <img
                    :src="item.browseIsVipMember == 0 ? `` : `${MemberIcon}`"
                    alt=""
                    style="margin-right: 5px"
                  />
                  <img
                    :src="
                      item.browseRealAuthStatus == 2
                        ? `${realAuthStatusIcon}`
                        : ``
                    "
                    alt=""
                  />
                </div>
              </div>
              <p class="qianmin" style="">
                <span>{{ item.browseAge + "岁" }}</span>
                <span v-if="item.browseWorkAddrCityName"
                  >&nbsp;|&nbsp;{{ item.browseWorkAddrCityName }}</span
                >
                <span v-if="item.browseOccBigCategory"
                  >&nbsp;|&nbsp;{{ item.browseOccBigCategory }}</span
                >
                <span v-if="item.browseHeight"
                  >&nbsp;|&nbsp;{{ item.browseHeight + "cm" }}</span
                >
                <span v-if="item.browseEducation"
                  >&nbsp;|&nbsp;{{ item.browseEducation }}</span
                >
                <span v-if="item.browseBuyCarStatus"
                  >&nbsp;|&nbsp;{{ item.browseBuyCarStatus }}</span
                >
                <span v-if="item.browseHouseStatus">
                  &nbsp;|&nbsp;{{ item.browseHouseStatus }}</span
                >
              </p>
              <!-- 时间 -->
              <!-- {{ $moment(item.NewTime).subtract(1, "days").calendar() }} -->
              <!-- {{ $moment(item.NewTime).format("H:mm ") }} -->
              <p
                class="time"
                v-if="
                  new Date(item.NewTime).getDate() == new Date().getDate() &&
                  new Date(item.NewTime).getMonth() == new Date().getMonth() &&
                  new Date(item.NewTime).getYear() == new Date().getYear()
                "
              >
                <!-- {{ $moment(item.NewTime).calendar() }} -->
                {{ $moment(item.NewTime).format("H:mm ") }}
              </p>
              <p
                class="time"
                v-else-if="
                  new Date(item.NewTime).getDate() ==
                    new Date().getDate() - 1 &&
                  new Date(item.NewTime).getMonth() == new Date().getMonth() &&
                  new Date(item.NewTime).getYear() == new Date().getYear()
                "
              >
                {{ $moment(item.NewTime).calendar() }}
              </p>
              <p class="time" v-else>
                {{ $moment(item.NewTime).format("MMM Do") }}
              </p>
              <!-- <p class="time" > -->
              <!-- {{ $moment(item.NewTime).calendar() }} -->
              <!-- </p> -->
            </div>
            <div class="Btn-box">
              <!-- 打招呼 -->
              <div>
                <div class="Btn" v-if="item.show" @click="sendL(item)">
                  <img
                    class="z"
                    src="../../../../assets/images/info/mess-aL.png"
                    alt=""
                  />
                  <img
                    class="b"
                    src="../../../../assets/images/info/mess-aaLL.png"
                    alt=""
                  />
                  打招呼
                </div>
                <div class="Btn-z" v-if="!item.show">已打招呼</div>
              </div>
              <!-- 关注 -->
              <div class="Btn" @click="guanzhu(item)">
                <img
                  src="../../../../assets/images/message/yezai_aixin.png"
                  alt=""
                  class="z"
                />
                <img
                  v-if="item.followStatus == 1"
                  src="../../../../assets/images/info/mess-aixin.png"
                  alt=""
                  class="b"
                />
                <div class="z">
                  {{ item.followStatus == 1 ? "关注" : "已关注" }}
                </div>
                <div v-if="item.followStatus == 1" class="b">关注</div>
                <div v-if="item.followStatus == 0" class="b">取消关注</div>
              </div>
              <!-- 发消息 -->
              <div class="Btn" @click="chat(item.objId)">
                <img
                  class="z"
                  src="../../../../assets/images/message/yezai_message_mail.png"
                  alt=""
                />
                <img
                  class="b"
                  src="../../../../assets/images/info/mess-e.png"
                  alt=""
                />
                发消息
              </div>
            </div>
          </li>

          <!-- 分页处理 -->
          <div class="low">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage1"
              :hide-on-single-page="valueP"
              background
              layout="prev, pager, next"
              :total="totalP"
            >
            </el-pagination>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/api/common/local.js";
// followin 关注
import { infocur, followingPage, followin } from "@/api/login/login.js"; //followPage
// import { sendmessage, subTk } from "@/api/member/member.js";
import { subTk } from "@/api/member/member.js";
// import { client } from "@/api/common/oss/oss.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { loveLove } from "@/api/my/my.js";

export default {
  data() {
    return {
      a: "",
      i: true,
      s: true,
      b: "",
      src: require("@/assets/images/index/avatar.png"),
      hong: false,
      xiao: true,
      Benoticed: [],
      noticed: [],
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      buyCarStatus: [
        {
          value: "已买车",
          label: "0",
        },
        {
          value: "未买车",
          label: "1",
        },
      ], //买车
      houseStatus: [
        {
          value: "和家人同住",
          label: "0",
        },
        {
          value: "已购房",
          label: "1",
        },
        {
          value: "租房",
          label: "2",
        },
        {
          value: "打算婚后购房",
          label: "3",
        },
        {
          value: "住在单位宿舍",
          label: "4",
        },
      ],
      //-----------------------
      pageSize: 10,
      total: null,
      value: null,
      currentPage4: 1,
      currentPage1: 1,
      totalP: null,
      valueP: false,
      zt: "",
      zy: "",
      dzh: true,
      follow: true,
      iSmessage: true,
      isVipMember: "",
      newarr: [],
      MemberIcon: require("@/assets/images/tubiao/yezai_member.png"),
      realAuthStatusIcon: require("@/assets/images/tubiao/yezai_auth.png"),
      idcard: "",
    };
  },
  created() {
    this.cs();
  },
  methods: {
    login() {
      console.log("this.", this.idcard);
      const _that = this;
      //-------------
      var options = {
        user: this.idcard,
        pwd: "yezai_user@easemob",
        // appKey: _that.WebIM.config.appkey,
        appKey: _that.$WebIM.config.appkey,
        success: function (res) {
          console.log("登录成功", res);
        },
        error: function () {},
      };
      // _that.WebIM.conn.open(options);
      _that.$WebIM.conn.open(options);
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 我关注
    async guanzhu(item) {
      if (item.followStatus == 1) {
        this.zy = 0;
      } else {
        this.zy = 1;
      }
      let a = {
        followMemberId: item.objId,
        opFlag: this.zy,
      };
      const { code } = await followin(a);
      if (code == 0) {
        this.cs();
        this.tkL();
      }
    },
    // 关注我的
    async gz(item) {
      console.log("用户", item.browseFollowStatus);
      if (item.browseFollowStatus == 1) {
        this.zt = 0;
      } else {
        this.zt = 1;
      }
      let a = {
        followMemberId: item.memberId,
        opFlag: this.zt,
      };

      const { code } = await followin(a);
      if (code == 0) {
        this.cs();
        this.tkL();
      }
    },
    //发消息
    async send(item) {
      item.show = !item.show;
      this.sendMess(item.memberId);//关注我的
    },
    //发消息02
    async sendL(item) {
      item.show = !item.show;
      this.sendMess(item.objId);
    },
    sendMess(o) {
      // let id = this.WebIM.conn.getUniqueId(); // 生成本地消息id
      // let msg = new this.WebIM.message("txt", id); // 创建文本消息
      console.log('数据',o);
      let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
      let msg = new this.$WebIM.message("txt", id); // 创建文本消息
      const _that = this;
      msg.set({
        msg: "你好", // 消息内容
        to: `${o}`, // -----------------------------------------------------------------------------------------(接收消息对象（用户id) )
        chatType: "singleChat", // 设置为单聊
        success: function (id, serverMsgId) {
          console.log("send private text Success", id, serverMsgId);
          _that.$message({
            message: "打招呼发送成功",
            type: "success",
            offset: 100,
          });
          let d = {
            tid: o,
          };
          loveLove(d);
          _that.tkL();

        },
        fail: function (e) {
          _that.$message({
            message: "打招呼发送失败",
            type: "error",
            offset: 100,
          });
          console.log("Send private text error", e);
        },
      });
      // console.log(msg.body);
      // _that.WebIM.conn.send(msg.body);
      _that.$WebIM.conn.send(msg.body);
    },
    chuji() {
      let rel = this.$router.resolve({
        path: "/n/myyezai",
      });
      window.open(rel.href, "_blank");
    },
    //聊天
    chat(item) {
      if (this.isVipMember == 0) {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else {
        let rel = this.$router.resolve({
          path: "/chat",
          query: {
            objectID: item,
          },
        });
        window.open(rel.href, "_blank");
      }
    },
    //跳转资料页
    ziliao(item) {
      let a = {
        id: item,
      };
      let rel = this.$router.resolve({
        path: "/u",
        query: a,
      });
      window.open(rel.href, "_blank");
    },
    //初始化获取当前用户信息
    async cs() {
      this.noticed = [];
      this.Benoticed = [];
      let a = local.get("access_token");
      const { code, data } = await infocur(a);
      if (code == 0) {
        this.isVipMember = data.user.isVipMember;
        this.idcard = data.user.id;
        this.login();
        //被关注
        let b = {
          objId: data.user.memberId,
          pageNum: this.currentPage4,
          pageSize: this.pageSize,
          opFlag: 0,
        };
        const Da = await followingPage(b);
        if (Da.code == 0) {
          console.log("数据", Da.data.records);
          // const _that = this;
          Da.data.records.map((item) => {
            item.opTime = this.$moment(item.opTime).format("l LTS");
            this.Benoticed.push(
              Object.assign({}, item, {
                show: true,
                NewTime: new Date(item.opTime).getTime(),
                Img: ossL(item.avatar),
                
              })
            );
          });

          this.total = Da.data.total;
          if (this.total == 0) {
            this.value = false;
          } else {
            this.value = true;
          }
          for (let i = 0; i < this.Benoticed.length; i++) {
            for (let z = 0; z < this.educations.length; z++) {
              if (this.Benoticed[i].education == this.educations[z].label) {
                this.Benoticed[i].education = this.educations[z].value;
              }
            }
          }
          for (let i = 0; i < this.Benoticed.length; i++) {
            for (let z = 0; z < this.buyCarStatus.length; z++) {
              if (
                this.Benoticed[i].buyCarStatus == this.buyCarStatus[z].label
              ) {
                this.Benoticed[i].buyCarStatus = this.buyCarStatus[z].value;
              }
            }
          }
          for (let i = 0; i < this.Benoticed.length; i++) {
            for (let z = 0; z < this.houseStatus.length; z++) {
              if (this.Benoticed[i].houseStatus == this.houseStatus[z].label) {
                this.Benoticed[i].houseStatus = this.houseStatus[z].value;
              }
            }
          }

          if (this.Benoticed.length == 0) {
            this.i = true;
          } else {
            this.i = false;
          }
          console.log("关注我的", this.Benoticed);
        }
        //关注
        let c = {
          memberId: data.user.memberId,
          pageNum: this.currentPage1,
          pageSize: this.pageSize,
          opFlag: 0,
        };
        const Data1 = await followingPage(c);
        if (Data1.code == 0) {
          console.log("数据", Data1.data.records); //opTime: "2021-09-22T15:06:55.000+0000"
          Data1.data.records.map((item) => {
            item.opTime = this.$moment(item.opTime).format("l LTS");
            this.noticed.push(
              Object.assign({}, item, {
                show: true,
                NewTime: new Date(item.opTime).getTime(),
                Img: ossL(item.browseAvatar)
              })
            );
          });

          this.totalP = Data1.data.total;
          //分页显示和隐藏
          if (this.totalP <= 10) {
            this.valueP = true;
          } else {
            this.valueP = false;
          }
          // 数据处理
          for (let i = 0; i < this.noticed.length; i++) {
            for (let z = 0; z < this.educations.length; z++) {
              if (this.noticed[i].browseEducation == this.educations[z].label) {
                this.noticed[i].browseEducation = this.educations[z].value;
              }
            }
          }
          for (let i = 0; i < this.noticed.length; i++) {
            for (let z = 0; z < this.buyCarStatus.length; z++) {
              if (
                this.noticed[i].browseBuyCarStatus == this.buyCarStatus[z].label
              ) {
                this.noticed[i].browseBuyCarStatus = this.buyCarStatus[z].value;
              }
            }
          }
          for (let i = 0; i < this.noticed.length; i++) {
            for (let z = 0; z < this.houseStatus.length; z++) {
              if (
                this.noticed[i].browseHouseStatus == this.houseStatus[z].label
              ) {
                this.noticed[i].browseHouseStatus = this.houseStatus[z].value;
              }
            }
          }
          if (this.noticed.length == 0) {
            this.s = true;
          } else {
            this.s = false;
          }
        }
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      let thisTime = new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      thisTime = thisTime.replace(/-/g, "/");
      console.log("shijian", thisTime);
      let time = new Date(thisTime);
      time = time.getTime();
      return time;
    },
    lie(num) {
      if (num == 1) {
        this.$refs.lie1.classList.add("active");
        this.$refs.lie2.classList.remove("active");
        this.xiao = true;
        this.hong = false;
      } else {
        this.$refs.lie1.classList.remove("active");
        this.$refs.lie2.classList.add("active");
        this.hong = true;
        this.xiao = false;
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.cs();
      this.currentPage1 = val;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    handleCurrentChangeL(val) {
      this.cs();
      this.currentPage4 = val;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    //跳转至会员
    member() {
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.low {
  /deep/ .el-pagination {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
  }
}
//分页样式修改
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fd686eff !important;
  color: #fff;
  border: none !important;
}
/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: #fff !important;
  min-width: 30px;
  border-radius: 2px;
  border: 1px solid #d8d8d8ff;
}
/deep/ .number {
  background-color: #fff !important;
  //  margin-left: 10px;
  border: 1px solid #d8d8d8ff !important;
}
/deep/ .btn-prev {
  border: 1px solid #d8d8d8ff;
  background-color: #fff !important;
  /deep/ .el-icon {
    display: block;
    font-size: 12px;
    font-weight: 700;
    width: 4px;
    height: 9px;
    background: #cccccc;
  }
}
//-------------
/deep/ .el-badge__content.is-fixed {
  right: 28px;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
//面包屑
.info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  position: relative;
  height: 32px;
  border-bottom: 1px solid #f4f4f4ff;
  padding-left: 32px;
  p {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
  }
  p::before {
    content: "";
    width: 4px;
    height: 16px;
    background: #fd686e;
    position: absolute;
    top: 23px;
    left: 20px;
  }
}
.choice {
  display: flex;
  padding-top: 15px;
  padding-left: 24px;
  p {
    cursor: pointer;
    margin-right: 30px;
    height: 32px;
  }
  .active {
    font-size: 14px;
    font-weight: 400;
    color: #fd686e;
    border-bottom: 2px solid #fd686e;
  }
}
.follow {
  min-height: 145px;
  .kong {
    // margin-top: 112px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 40px;
    }
    .btn-box {
      margin-top: 60px;
      width: 222px;
      height: 40px;
      background: #fd686e;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
    }
    .btn-gz {
      display: flex;
      margin-top: 60px;
      .btn-gz-a {
        width: 120px;
        height: 40px;
        margin-right: 10px;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fd686e;
        color: #ffffff;
        cursor: pointer;
      }
      .btn-gz-b {
        width: 120px;
        border-radius: 4px;
        box-sizing: border-box;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #b1b1b1;
        color: #b1b1b1;
        cursor: pointer;
      }
    }
  }
  .messages-li {
    li:nth-child(1) {
      margin-top: 8px;
    }
    li {
      display: flex;
      padding-top: 24px;
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #ececec;
      padding-bottom: 14px;
      .touxi {
        // display: flex;
        // width: 106px;

        // margin-right: 10px;
        // box-sizing: border-box;
        // margin-left: 11px;
        // .xixi {
        // width: 80px;
        // height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 106px;
        height: 106px;
        margin-right: 10px;
        box-sizing: border-box;
        margin-left: 11px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 4px;
        }
        // }
      }
      .mess-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .mess-name {
          p {
            font-size: 16px;
          }
        }
        .qianmin {
          width: 408px;
          height: 20px;
          font-size: 14px;
          color: #666;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            color: #666;
          }
        }
        .time {
          color: #adadad;
        }
      }
      .Btn-box {
        display: flex;
        // justify-content: center;
        // align-items: center;
        margin-top: 68px;
        .Btn {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #ffffffff;
          box-sizing: border-box;
          color: #fd686eff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          .b {
            display: none;
          }
          img {
            margin-right: 4px;
          }
        }
        .Btn:hover {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #fe747aff;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          .z {
            display: none;
          }
          .b {
            display: flex;
          }
          img {
            margin-right: 4px;
          }
        }
        .Btn-a {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #fe747aff;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          img {
            margin-right: 4px;
          }
        }
        .Btn-b {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #fe747aff;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
        }
        .Btn-c {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          border: 1px solid #ecececff;
          background: #fe747aff;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          img {
            margin-right: 4px;
          }
        }
        .Btn-z {
          width: 98px;
          height: 36px;
          margin-right: 7px;
          background-color: #dbdbdb;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          border-radius: 6px;
        }
      }
    }
    li:hover {
      background-color: #fde9eaff;
      cursor: pointer;
    }
    .close {
      display: none;
      position: absolute;
      top: 16px;
      right: 20px;
      color: #adadadff;
      font-weight: bold;
    }
    li:hover .close {
      display: inline-block;
    }
  }
}
</style>
